export const INVOICES = 'INVOICES'
export const INVOICES_ADD = 'INVOICES_ADD'
export const INVOICES_DATA = 'INVOICES_DATA'
export const INVOICES_DELETE = 'INVOICES_DELETE'
export const INVOICES_EXPORT = 'INVOICES_EXPORT'
export const INVOICES_EXPORT_LOADED = 'INVOICES_EXPORT_LOADED'
export const INVOICES_EXPORT_LOADING = 'INVOICES_EXPORT_LOADING'
export const INVOICES_FAIL = 'INVOICES_FAIL'
export const INVOICES_FORM = 'INVOICES_FORM'
export const INVOICES_FORM_DELETE_ROW = 'INVOICES_FORM_DELETE_ROW'
export const INVOICES_FORM_ITEMS = 'INVOICES_FORM_ITEMS'
export const INVOICES_INIT = 'INVOICES_INIT'
export const INVOICES_PAYMENTS = 'INVOICES_PAYMENTS'
export const INVOICES_PAYMENTS_DATA = 'INVOICES_PAYMENTS_DATA'
export const INVOICES_PAYMENTS_EXPORT = 'INVOICES_PAYMENTS_EXPORT'
export const INVOICES_SUBMIT_EXPORT = 'INVOICES_SUBMIT_EXPORT'
export const INVOICES_SUCCESS = 'INVOICES_SUCCESS'
export const INVOICES_UPDATE = 'INVOICES_UPDATE'
export const INVOICES_VIEW = 'INVOICES_VIEW'
export const INVOICES_VIEW_DATA = 'INVOICES_VIEW_DATA'