import {
    INVOICES_ADD,
    INVOICES_DATA,
    INVOICES_DELETE,
    INVOICES_EXPORT_LOADED,
    INVOICES_EXPORT_LOADING,
    INVOICES_EXPORT,
    INVOICES_FAIL,
    INVOICES_FORM,
    INVOICES_FORM_DELETE_ROW,
    INVOICES_FORM_ITEMS,
    INVOICES_INIT,
    INVOICES_PAYMENTS_DATA,
    INVOICES_PAYMENTS_EXPORT,
    INVOICES_PAYMENTS,
    INVOICES_SUBMIT_EXPORT,
    INVOICES_SUCCESS,
    INVOICES_UPDATE,
    INVOICES_VIEW_DATA,
    INVOICES_VIEW,
    INVOICES,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    dataView: {},
    dataInvoiceDetailPayments: {},
    isInvoiceExportLoading: false,
    submitExport: false,
    response: '',
    alertType: '',
}

const invoices = (state = INIT_STATE, action) => {
    const {type, payload} = action
    let items = []
    let add_ons = []

    switch (type) {
        case INVOICES_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
            }
        case INVOICES_SUBMIT_EXPORT:
            return {
                ...state,
                submitExport: payload.params,
            }
        case INVOICES_ADD:
            Object.entries(JSON.parse(payload.params.items)).map(([key, value]) => {
                if (typeof value.id != 'undefined') {
                    items.push(value)
                }
            })
            if (items.length > 0) {
                payload.params.items = JSON.stringify(items)
            } else {
                payload.params.items = ''
            }

            return {...state}
        case INVOICES:
        case INVOICES_UPDATE:
        case INVOICES_DELETE:
        case INVOICES_EXPORT:
        case INVOICES_VIEW:
        case INVOICES_PAYMENTS:
        case INVOICES_PAYMENTS_EXPORT:
            return {...state}
        case INVOICES_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
        case INVOICES_VIEW_DATA:
            return {
                ...state,
                dataView: getPropTraverse(payload, ['data']),
            }
        case INVOICES_FORM:
            if (getPropTraverse(action.payload.params, ['invoice'])) {
                return {
                    ...state,
                    dataView: {
                        ...state.dataView,
                        invoice: {
                            ...state.dataView.invoice,
                            ...action.payload.params.invoice,
                        }
                    }
                }
            }

            if (getPropTraverse(action.payload.params, ['package_items','0', 'package_details'])) {
                return {
                    ...state,
                    dataView: {
                        ...state.dataView,
                        package_items: state.dataView.package_items.map(item => ({
                            ...item,
                            package_details: action.payload.params.package_items[0].package_details
                        }))
                    }
                }
            }

            return {
                ...state,
                dataView: {
                    ...state.dataView,
                    ...action.payload.params,
                }
            }
        case INVOICES_FORM_ITEMS:
            return {
                ...state,
                dataView: {
                    ...state.dataView,
                    package_items: state.dataView.package_items.map(item => ({
                        ...item,
                        add_ons: action.payload.params
                    }))
                }
            }
        case INVOICES_PAYMENTS_DATA:
            const data = getPropTraverse(payload, ['data'])
            return {
                ...state,
                dataInvoiceDetailPayments: data,
            }
        case INVOICES_EXPORT_LOADING:
            return {
                ...state,
                isInvoiceExportLoading: true
            }
        case INVOICES_EXPORT_LOADED:
            return {
                ...state,
                isInvoiceExportLoading: false
            }
        case INVOICES_FAIL:
        case INVOICES_SUCCESS:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case INVOICES_FORM_DELETE_ROW:
            add_ons = state.dataView.package_items[0].add_ons.filter((item, index) => {
                return action.payload.idx !== index
            })

            return {
                ...state,
                dataView: {
                    ...state.dataView,
                    package_items: state.dataView.package_items.map(item => ({
                        ...item,
                        add_ons: add_ons
                    }))
                }
            }
    }

    return {...state}
}

export default invoices