import React from 'react'
import {Redirect} from 'react-router-dom'

// app
import Dashboard from '../pages/Dashboard/Dashboard'
import Profile from '../pages/Profile/Profile'
import Login from '../pages/Login/Login'
import Logout from '../pages/Logout/Logout'
import SignUp from '../pages/SignUp/SignUp'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword'
import ResetPassword from '../pages/ResetPassword/ResetPassword'
import ProductsServices from '../pages/ProductsServices/ProductsServices'
import BuildPackage from '../pages/PackageBuilder/Create'
import UpdatePackage from '../pages/PackageBuilder/Update'
import PackageBuilder from '../pages/PackageBuilder/PackageBuilder'
import Invoices from '../pages/Invoices/Invoices'
import CreateInvoice from '../pages/Invoices/Create'
import ViewInvoice from '../pages/Invoices/View'
import UpdateInvoice from '../pages/Invoices/Update'
import Customers from '../pages/Customers/Customers'
import Bookings from '../pages/Bookings/Bookings'
import CreateBooking from '../pages/Bookings/Create'
import UpdateBooking from '../pages/Bookings/Update'
import BookingFormLanding from 'pages/BookingFormLanding/LandingForm'
import BusinessInfo from 'pages/BusinessInfo/BusinessInfo'
import Microsites from 'pages/Microsites/Microsites'
import InvoicePayments from '../pages/Invoices/InvoicePayments'

const superAdminRoutes = [
    {path: '/acl/users', name: 'acl-users', component: Dashboard},
]

const authProtectedRoutes = [
    {path: '/bookings', name: 'bookings', component: Bookings},
    {path: '/bookings/:id/update', name: 'update-booking', component: UpdateBooking},
    {path: '/bookings/create', name: 'create-booking', component: CreateBooking},
    {path: '/business-info', name: 'business-info', component: BusinessInfo},
    {path: '/customers', name: 'customers', component: Customers},
    {path: '/dashboard', name: 'dashboard', component: Dashboard},
    {path: '/invoices', name: 'invoices', component: Invoices},
    {path: '/invoices/:id/view', name: 'view-invoice', component: ViewInvoice},
    {path: '/invoices/update/:id', name: 'update-invoice', component: UpdateInvoice},
    {path: '/invoices/create', name: 'create-invoice', component: CreateInvoice},
    {path: '/logout', name: 'logout', component: Logout},
    {path: '/microsites', name: 'microsites', component: Microsites},
    {path: '/package-builder', name: 'package-builder', component: PackageBuilder},
    {path: '/package-builder/create', name: 'create-package', component: BuildPackage},
    {path: '/package-builder/update/:id', name: 'update-package', component: UpdatePackage},
    {path: '/products-services', name: 'products-services', component: ProductsServices},
    {path: '/profile', name: 'profile', component: Profile},
    {path: '/invoices/:id/payments', name: 'view-invoice-payments', component: InvoicePayments},


    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {path: '/', exact: true, component: () => <Redirect to="dashboard"/>}
]

const publicRoutes = [
    // {path: '/login', name: 'login', component: Login},
    // {path: '/forgot-password', component: ForgetPwd},
    // {path: '/register', component: Register}
    {path: '/microsite/:token', name: 'microsite', component: BookingFormLanding},
]

const publicRedirectToProtectedRoutes = [
    {path: '/login', name: 'login', component: Login},
    {path: '/sign-up', name: 'sign-up', component: SignUp},
    {path: '/forgot-password', name: 'forgot-password', component: ForgotPassword},
    {path: '/reset-password', name: 'reset-password', component: ResetPassword},
]

const allRoutes = [...authProtectedRoutes, ...publicRoutes, ...publicRedirectToProtectedRoutes, ...superAdminRoutes]

export {authProtectedRoutes, publicRoutes, publicRedirectToProtectedRoutes, superAdminRoutes, allRoutes}
