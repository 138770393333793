import {
    INVOICES_ADD,
    INVOICES_DATA,
    INVOICES_DELETE,
    INVOICES_EXPORT_LOADED,
    INVOICES_EXPORT_LOADING,
    INVOICES_EXPORT,
    INVOICES_FAIL,
    INVOICES_FORM_DELETE_ROW,
    INVOICES_FORM_ITEMS,
    INVOICES_FORM,
    INVOICES_INIT,
    INVOICES_PAYMENTS_DATA,
    INVOICES_PAYMENTS_EXPORT,
    INVOICES_PAYMENTS,
    INVOICES_SUBMIT_EXPORT,
    INVOICES_SUCCESS,
    INVOICES_UPDATE,
    INVOICES_VIEW_DATA,
    INVOICES_VIEW,
    INVOICES,
} from './actionTypes'

export const invoicesAction = (params) => ({
    type: INVOICES,
    payload: {params},
})

export const invoicesInitAction = (params) => ({
    type: INVOICES_INIT,
    payload: params,
})

export const invoicesDataAction = (data) => ({
    type: INVOICES_DATA,
    payload: {data},
})

export const invoicesUpdateAction = (params, meta) => ({
    type: INVOICES_UPDATE,
    payload: {params, meta},
})

export const invoicesAddAction = (params, meta) => ({
    type: INVOICES_ADD,
    payload: {params, meta},
})

export const invoicesDeleteAction = (params, meta) => ({
    type: INVOICES_DELETE,
    payload: {params, meta},
})

export const invoicesExportAction = (params, meta) => ({
    type: INVOICES_EXPORT,
    payload: {params, meta},
})

export const invoicesExportLoadingAction = (params, meta) => ({
    type: INVOICES_EXPORT_LOADING,
    payload: {params, meta},
})

export const invoicesExportLoadedAction = (params, meta) => ({
    type: INVOICES_EXPORT_LOADED,
    payload: {params, meta},
})

export const invoicesViewAction = (params) => ({
    type: INVOICES_VIEW,
    payload: {params},
})

export const invoicesViewDataAction = (data) => ({
    type: INVOICES_VIEW_DATA,
    payload: {data},
})

export const invoicesSuccessAction = (params) => ({
    type: INVOICES_SUCCESS,
    payload: params,
})

export const invoicesFailAction = (params) => ({
    type: INVOICES_FAIL,
    payload: params,
})

export const invoicesSubmitExportAction = (params) => ({
    type: INVOICES_SUBMIT_EXPORT,
    payload: {params},
})

export const invoicesPaymentsAction = (params, reload = true) => ({
    type: INVOICES_PAYMENTS,
    payload: {params, reload},
})

export const invoicesPaymentsDataAction = (data) => ({
    type: INVOICES_PAYMENTS_DATA,
    payload: {data},
})

export const invoicesPaymentsExportAction = (params) => ({
    type: INVOICES_PAYMENTS_EXPORT,
    payload: {params},
})

export const invoicesFormAction = (params) => ({
    type: INVOICES_FORM,
    payload: {params},
})

export const invoicesFormItemsAction = (params, idx) => ({
    type: INVOICES_FORM_ITEMS,
    payload: {params, idx},
})

export const invoiceFormDeleteRowAction = (params, idx) => ({
    type: INVOICES_FORM_DELETE_ROW,
    payload: {params, idx},
})