import {
    BOOKINGS,
    BOOKINGS_DATA,
    BOOKINGS_ADD,
    BOOKINGS_UPDATE,
    BOOKINGS_DELETE,
    BOOKINGS_FORM,
    BOOKINGS_INIT,
    BOOKINGS_SUCCESS,
    BOOKINGS_FAIL,
    BOOKING_EVENTS,
    BOOKING_EVENTS_DATA,
    BOOKINGS_VIEW,
    BOOKINGS_VIEW_DATA,
    BOOKING_EXPORT,
    BOOKING_EXPORT_LOADING,
    BOOKING_EXPORT_LOADED,
    BOOKINGS_LOADING_DATA,
    BOOKINGS_LOADED_DATA,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE_FORM = {
    booking_id: '',
    contact_person_first_name: '',
    contact_person_last_name: '',
    contact_person_email: '',
    contact_person_contact_number: '',
    celebrant_first_name: '',
    celebrant_last_name: '',
    celebrant_nickname: '',
    celebrant_date_of_birth: '',
    celebrant_gender: {
        label: '',
        value: ''
    },
    ceremony_full_address: '',
    ceremony_map_url: '',
    reception_full_address: '',
    reception_map_url: '',
    guest_count: {
        label: '',
        value: ''
    },
    venue_types: [],
    persona_types: [],
    package: {
        id: '',
    },
    products_services_addons: [],
    contact_person: {
        id: '',
        contact_id: '',
        email: '',
        first_name: '',
        last_name: '',
        full_name: '',
        label: '',
        value: '',
        contact_details: [],
    },
    ceremony_event_datetime_start: '',
    ceremony_event_datetime_end: '',
    reception_event_datetime_start: '',
    reception_event_datetime_end: ''
}

const INIT_STATE = {
    data: [],
    isBookingExportLoading: false,
    bookingType: {},
    form: {
        category: {
            category_code: {
                label: '',
                value: ''
            },
            category_type_code: ''
        },
        ...INIT_STATE_FORM,
    },
    response: '',
    alertType: '',
}

const bookings = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case BOOKINGS_INIT:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...INIT_STATE_FORM,
                },
                response: '',
                alertType: '',
            }
        case BOOKINGS_LOADED_DATA:
            return {
                ...state,
                isDataLoading: false
            }
        case BOOKINGS_LOADING_DATA:
            return {
                ...state,
                isDataLoading: true
            }
        case BOOKINGS:
        case BOOKING_EVENTS:
        case BOOKINGS_ADD:
        case BOOKINGS_UPDATE:
        case BOOKINGS_DELETE:
        case BOOKINGS_VIEW:
        case BOOKING_EXPORT:
            return {...state}
        case BOOKINGS_SUCCESS:
        case BOOKINGS_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case BOOKINGS_VIEW_DATA:
            const data = getPropTraverse(payload, ['data'])
            const guestCount = getPropTraverse(data, ['guest_count'])
            const contactPerson = getPropTraverse(data, ['booking_primary_contact'])
            const personaDetails = getPropTraverse(data, ['booking_persona_details'])
            const bookingInvoiceItems = getPropTraverse(data, ['booking_invoices', 'invoice_summary', 'invoice_items'])
            const personaTypeCode = personaDetails[0].persona_type.persona_type_code
            const eventDetails = getPropTraverse(data, ['booking_event_details'])
            const bookingTypeName = data.booking_type.booking_type_name
            const bookingTypeCode = data.booking_type.booking_type_code

            let personaObjProp = ''
            let personaObj = {}
            let personaObjSfx = ['id', 'contact_id', 'first_name', 'last_name', 'nickname', 'date_of_birth', 'gender']

            if (personaTypeCode == 'celebrant') {
                Object.entries(personaObjSfx).map(([key, val]) => {
                    personaObjProp = personaTypeCode + '_' + val

                    if (val == 'gender') {
                        personaObj[personaObjProp] = {
                            label: personaDetails[0][val + '_type'][val + '_type_name'],
                            value: personaDetails[0][val + '_type'][val + '_type_code'],
                        }
                    } else {
                        personaObj[personaObjProp] = personaDetails[0][val]
                    }
                })
            }

            let eventDetailsObj = {}
            let eventObjProp = ''
            let venueTypeCode = ''
            let eventObjSfx = ['id', 'full_address', 'map_url', 'event_datetime_start', 'event_datetime_end']

            Object.entries(eventDetails).map(([key, value]) => {
                venueTypeCode = value.venue_type.venue_type_code
                Object.entries(eventObjSfx).map(([key, val]) => {
                    eventObjProp = venueTypeCode + '_' + val
                    eventDetailsObj = {...eventDetailsObj, ...{
                        [eventObjProp]: value[val]
                    }}
                })
            })


            let productServicePackageId = ''
            let productServicesAddons = []
            if (bookingInvoiceItems != null) {
                Object.entries(bookingInvoiceItems).map(([key, value]) => {
                    if (value.is_addon == 1) {
                        productServicesAddons.push(value)
                    }
                    if (getPropTraverse(value, ['products_services_package_id']) != null) {
                        productServicePackageId = getPropTraverse(value, ['products_services_package_id'])
                    }
                })
            }

            return {
                ...state,
                form: {
                    category: {
                        category_code: {
                            label: bookingTypeName,
                            value: bookingTypeCode
                        },
                        category_type_code: 'booking_types'
                    },
                    guest_count: {
                        label: getPropTraverse(guestCount, ['guest_count_name'], true),
                        value: getPropTraverse(guestCount, ['guest_count_code'], true),
                    },
                    package: {
                        id: productServicePackageId,
                    },
                    products_services_addons: productServicesAddons,
                    venue_types: {...state.form.venue_types},
                    persona_types: {...state.form.persona_types},
                    contact_person: {
                        id: getPropTraverse(contactPerson, ['id'], true),
                        contact_id: getPropTraverse(contactPerson, ['contact_id'], true),
                        email: getPropTraverse(contactPerson, ['email'], true),
                        full_name: getPropTraverse(contactPerson, ['booking_contact_details', 'full_name'], true),
                        label: getPropTraverse(contactPerson, ['booking_contact_details', 'full_name'], true),
                        value: getPropTraverse(contactPerson, ['id'], true),
                        contact_details: [
                            {
                                id: getPropTraverse(contactPerson, ['primary_contact_details', 'id'], true),
                                booking_contact_id: getPropTraverse(contactPerson, ['primary_contact_details', 'booking_contact_id'], true),
                                contact_number: getPropTraverse(contactPerson, ['primary_contact_details', 'contact_number'], true),
                            }
                        ]
                    },
                    ...eventDetailsObj,
                    ...personaObj,
                }
            }
        case BOOKINGS_FORM:
            return {
                ...state,
                form: {
                    ...state.form, ...payload.params,
                }
            }
        case BOOKINGS_DATA:
        case BOOKING_EVENTS_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
        case BOOKING_EXPORT_LOADING:
            return {
                ...state,
                isBookingExportLoading: true
            }
        case BOOKING_EXPORT_LOADED:
            return {
                ...state,
                isBookingExportLoading: false
            }
    }

    return {...state}
}

export default bookings