import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Button, Card, CardBody, Col, Label, Row, Spinner} from 'reactstrap'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import Select from 'react-select'
import {
    productsServicesAction,
    contactsAction,
    invoicesUpdateAction,
    invoicesInitAction,
    codeTableOptionsAction,
    componentModalToggleAction,
    invoicesSubmitExportAction,
    componentFormOnSubmitAction,
    componentFormInitAction,
    invoicesFormAction,
    invoicesFormItemsAction,
    invoiceFormDeleteRowAction,
    packageBuilderDetailsAction,
} from 'store/actions'
import {ddlbParser, decimalPlace} from 'app.cms.react/helpers/utils'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import ModalAddContacts from '../Contacts/ModalAddContacts'
import ModalAddProductsServices from '../ProductsServices/ModalAddProductsServices'
import FormAlert from '../Common/Alerts/FormAlert'
import { NumericFormat } from 'react-number-format'
import {invoicesViewAction} from 'app.cms.react/store/actions'
import parse from 'html-react-parser'

const formatOptionLabelProductsServices = ({ value, label, price, description }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>
                <div className="clearfix">
                    <div className="float-start">{label}</div>
                    <div className="float-end">
                        <NumericFormat value={decimalPlace(price)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                    </div>
                </div>
                <div>
                    <small>{description}</small>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const formatOptionLabelCustomers = ({ value, label, email }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>{label}<br /><small>{email}</small></React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const formatOptionLabelPackages = ({ value, label, package_price }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>{label}<br /><small><NumericFormat value={decimalPlace(package_price)} displayType="text" thousandSeparator={true} decimalSeparator="." /></small></React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const RenderCustomerDetail = (props) => {
    const data = props.customer
    return (
        <React.Fragment>
            <p>
                <strong>{data.full_name}</strong><br />
                {data.email}<br />
                {data.contact_number}<br />
                {data.address}
            </p>
        </React.Fragment>
    )
}

const RenderPackageDetail = (props) => {
    const data = props.package

    if (data.id == '') return ''

    return (
        <React.Fragment>
            <strong>{data.name}</strong>
            <div>{parse(data.description)}</div>
            {(data.items.length > 0) ? (
                <div className="mt-3 mb-3">
                    <strong>Inclusions:</strong>
                    <ul className="list-group">
                        {Object.entries(data.items).map(([key, item]) => (
                            <li className="list-group-item border-0 me-0 px-0 py-1" key={key}><i className="bx bx-check text-success"></i> {item.name}</li>
                        ))}
                    </ul>
                </div>
            ) : ''}
            <div>
                <strong>Package Price: <NumericFormat value={decimalPlace(Number(data.package_price))} displayType="text" thousandSeparator={true} decimalSeparator="." /></strong>
            </div>
        </React.Fragment>
    )
}

const initStateItems = () => {
    return {
        product_service_desc: '',
        product_service_id: '',
        product_service_name: '',
        products_services_package_id: '',
        description: '',
        quantity: 1,
        price: 0.00,
        discount_price: 0.00,
        amount: 0.00,
        is_addon: 0,
    }
}

class FormUpdateInvoices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            invoice: {
                invoice_status: {
                    payment_status_code: '',
                    payment_status_name: '',
                }
            },
            payment_due_date: '',
            invoice_date: '',
            items: [initStateItems()],
            total_amount: 0.00,
            customer: {
                full_name: '',
                email: '',
                contact_number: '',
                address: '',
            },
            package: {
                id: '',
                name: '',
                description: '',
                items: [],
                package_price: 0.00,
            },
            toggleAddContactModalProps: {
                target: 'addContacts',
                isOpen: true,
            },
            toggleAddProductsServicesModalProps: {
                target: 'addProductsServices',
                isOpen: true,
            },
        }

        this.props.invoicesInitAction()
        this.props.componentFormInitAction()
        this.props.productsServicesAction()
        this.props.contactsAction({type: 'customer'})
        this.props.codeTableOptionsAction({
            table: 'countries',
            type: 'ddlb'
        })
        this.props.codeTableOptionsAction({
            table: 'payment_statuses',
            type: 'ddlb'
        })
        this.props.packageBuilderDetailsAction()

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleDdlbChange = this.handleDdlbChange.bind(this)
        this.handleRecomputeAddOnAmount = this.handleRecomputeAddOnAmount.bind(this)
        this.handleRecomputeTotalAmount = this.handleRecomputeTotalAmount.bind(this)

        /** For update functionality */
        this.props.invoicesViewAction({invoice_id: this.props.invoiceId})
        this.handleDdlbDefaultValue = this.handleDdlbDefaultValue.bind(this)
        this.handleTextDefaultValue = this.handleTextDefaultValue.bind(this)
        this.handleSetInvoicePaymentStatus = this.handleSetInvoicePaymentStatus.bind(this)
    }

    handleAddRow = () => {
        const stateList = this.props.dataView.package_items[0].add_ons
        stateList.push(initStateItems())

        this.setState({ items: stateList })
    }

    handleAddContact = () => {
        this.props.componentModalToggleAction(this.state.toggleAddContactModalProps)
    }

    handleAddProductService = () => {
        this.props.componentModalToggleAction(this.state.toggleAddProductsServicesModalProps)
    }

    handleRemoveRow = (e, idx) => {
        const stateList = this.props.dataView.package_items[0].add_ons.filter((item, index) => {
            return idx !== index
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})

        this.props.invoiceFormDeleteRowAction(stateList, idx)
    }

    handleDdlbChange = (value, idx, type) => {
        switch (type) {
            case 'customer':
                this.handleSetCustomerDetailOnSelect(value)
                break
            case 'product_service':
                this.handleSetProductServiceItemOnSelect(value, idx)
                break
            case 'invoice_date':
            case 'payment_due_date':
                this.handleSetDateOnSelect(value, type)
                break
            case 'products_services_packages':
                this.handleSetProductsServicesPackagesOnSelect(value, idx)
                break
            case 'invoice_status':
                this.handleSetInvoicePaymentStatus(value, idx)
                break
        }
    }

    handleSetInvoicePaymentStatus = (value) => {
        this.props.invoicesFormAction({
            invoice: {
                invoice_status: {
                    payment_status_code: value.value,
                    payment_status_name: value.label,
                }
            }
        })

        this.setState({
            invoice: {
                invoice_status: {
                    payment_status_code: value.value,
                    payment_status_name: value.label,
                }
            }
        })
    }

    handleTextChange = (e) => {
        this.props.invoicesFormAction({
            invoice: {
                notes: e.target.value
            }
        })
    }

    handleSetProductsServicesPackagesOnSelect(value, idx) {
        this.props.invoicesFormAction({
            invoice: {
                package_amount: value.package_price
            }
        })

        this.props.invoicesFormAction({
            package_items: [{
                package_details: {
                    id: value.value,
                    package_name: value.label,
                    description: value.description,
                    package_price: value.package_price,
                }
            }],
        })

        this.setState({
            package: {
                id: value.value,
                name: value.label,
                description: value.description,
                items: value.items,
                package_price: value.package_price
            },
        })
    }

    handleSetProductServiceItemOnSelect(value, idx) {
        const stateList = Object.entries(this.props.dataView.package_items[0].add_ons).map(([key, val]) => {
            if (key == idx) {
                return {
                    invoice_id: val.invoice_id,
                    products_services_package_id: val.products_services_package_id,
                    product_service_id: value.id,
                    product_service_name: value.name,
                    product_service_desc: val.description,
                    is_addon: val.is_addon,
                    sort: val.sort,
                    quantity: val.quantity,
                    price: value.price,
                    amount: val.quantity * value.price,
                    discount_price: val.discount_price,
                    currency_code: val.currency_code,
                    currency_symbol: val.currency_code
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.props.invoicesFormItemsAction(stateList, idx)
    }

    handleRecomputeTotalAmount(e, type) {
        const discountPrice = e.target.value
        const totalPrice = Number(this.props.dataView.invoice.package_amount) + Number(this.props.dataView.invoice.add_ons_amount)

        this.props.invoicesFormAction({
            invoice: {
                total_amount: Number(this.props.dataView.invoice.total_amount) - Number(discountPrice),
                discount_price: Number(discountPrice)
            }
        })
    }

    handleRecomputeAddOnAmount(e, idx, type) {
        const targetValue = e.target.value

        const stateList = Object.entries(this.props.dataView.package_items[0].add_ons).map(([key, val]) => {
            if (key == idx) {
                switch (type) {
                    case 'quantity':
                        return {
                            invoice_id: val.invoice_id,
                            products_services_package_id: val.products_services_package_id,
                            product_service_id: val.product_service_id,
                            product_service_name: val.product_service_name,
                            product_service_desc: val.description,
                            is_addon: val.is_addon,
                            sort: val.sort,
                            quantity: targetValue,
                            price: val.price,
                            amount: targetValue * val.price,
                            discount_price: val.discount_price,
                            currency_code: val.currency_code,
                            currency_symbol: val.currency_code
                        }
                    case 'discount_price':
                        return {
                            invoice_id: val.invoice_id,
                            products_services_package_id: val.products_services_package_id,
                            product_service_id: val.product_service_id,
                            product_service_name: val.product_service_name,
                            product_service_desc: val.description,
                            is_addon: val.is_addon,
                            sort: val.sort,
                            quantity: val.quantity,
                            price: val.price,
                            amount: (val.price * val.quantity) - targetValue,
                            discount_price: targetValue,
                            currency_code: val.currency_code,
                            currency_symbol: val.currency_code
                        }
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.props.invoicesFormItemsAction(stateList, idx)
    }

    computeSubTotal(e, idx, type) {
        let discount = 0.00

        const stateList = Object.entries(this.props.dataView.invoice_items).map(([key, val]) => {
            if (key == idx) {
                switch (type) {
                    case 'quantity':
                        discount = (val.discount_price < (e.target.value * val.price)) ? e.target.value * val.price - val.discount_price : discount
                        return {
                            ...val,
                            quantity: e.target.value,
                            amount: discount,
                        }
                    case 'price':
                        discount = (val.discount_price < (e.target.value * val.quantity)) ? e.target.value * val.quantity - val.discount_price : discount
                        return {
                            ...val,
                            price: e.target.value,
                            amount: discount,
                        }
                    case 'discount_price':
                        discount = (e.target.value < (val.price * val.quantity)) ? val.price * val.quantity - e.target.value : discount
                        return {
                            ...val,
                            discount_price: e.target.value,
                            amount: discount,
                        }
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        // this.setState({items: stateList})

        this.props.invoicesFormItemsAction(stateList, idx)
    }

    computeTotal(stateList) {
        let total = 0.00

        Object.entries(stateList).map(([key, value]) => {
            total += (value.amount == null) ? 0.00 : Number(value.amount)
        })

        this.props.invoicesFormAction({
            invoice: {
                add_ons_amount: Number(total)
            }
        })
    }

    handleSetCustomerDetailOnSelect(value) {
        this.props.invoicesFormAction({
            contact_id: value.id,
            first_name: value.first_name,
            last_name: value.last_name,
            full_name: value.full_name,
            email: value.email,
        })

        const addressLine1 = (value.address_details[0].address_line_1 == null) ? '' : value.address_details[0].address_line_1 + ', '
        const addressLine2 = (value.address_details[0].address_line_2 == null) ? '' : value.address_details[0].address_line_2 + ', '

        this.setState({
            customer: {
                id: value.id,
                full_name: value.full_name,
                email: value.email,
                contact_number: value.contact_details[0].contact_number,
                address: addressLine1 + addressLine2 + value.address_details[0].country_name
            }
        })
    }

    handleSetDateOnSelect(value, type) {
        this.props.invoicesFormAction({
            invoice: {
                [type]: value
            }
        })
    }

    handleValidationSchema() {
        return Yup.object().shape({
            // product_service_desc: Yup.string().required(
            //     this.props.t('Product / Service name is required')
            // ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    handleFormSubmit(values) {
        this.props.invoicesUpdateAction({
            invoice_id: this.props.invoiceId,
            contact_person: {
                id: this.props.dataView.contact_id,
            },
            discount_price: this.props.dataView.invoice.discount_price,
            package: {
                ...this.props.dataView.package_items[0].package_details,
              ...{
                items: this.props.dataView.package_items[0].invoice_items,
              }
            },
            products_services_addons: this.props.dataView.package_items[0].add_ons,
            invoice_date: this.props.dataView.invoice.invoice_date,
            payment_due_date: this.props.dataView.invoice.payment_due_date,
            invoice_status: {
                invoice_status_code: this.props.dataView.invoice.invoice_status.payment_status_code,
                invoice_status_name: this.props.dataView.invoice.invoice_status.payment_status_name,
            },
            notes: this.props.dataView.invoice.notes,
            submit_export: this.props.submitExport
        })
        this.props.componentFormOnSubmitAction()
    }

    handleDdlbDefaultValue(idx, type) {
        switch (type) {
            case 'customer':
                return {
                    label: this.props.dataView.full_name,
                    value: this.props.dataView.contact_id,
                }
            case 'products_services_packages':
                return {
                    label: this.props.dataView.package_items[0].package_details.package_name,
                    value: this.props.dataView.package_items[0].package_details.id,
                }
            case 'product_service':
                return {
                    label: this.props.dataView.package_items[0].add_ons[idx].product_service_name,
                    value: this.props.dataView.package_items[0].add_ons[idx].products_services_package_id,
                }
            case 'invoice_status':
                return {
                    label: this.props.dataView.invoice.invoice_status.payment_status_name,
                    value: this.props.dataView.invoice.invoice_status.payment_status_code,
                }
            default:
                if (typeof this.props.dataView.invoice_items[idx] != 'undefined') {
                    return {
                        label: this.props.dataView.invoice_items[idx].product_service_name,
                        value: this.props.dataView.invoice_items[idx].product_service_id
                    }
                }
        }
    }

    handleTextDefaultValue(type) {
        switch (type) {
            case 'invoice_date':
            case 'payment_due_date':
            case 'notes':
                return (this.props.dataView.invoice[type] != null) ? this.props.dataView.invoice[type] : ''
            default:
                return (this.props.dataView[type] != null) ? this.props.dataView[type] : ''
        }
    }

    render() {
        return (
            <React.Fragment>
                {(Object.keys(this.props.dataView).length > 0) ? (
                    <React.Fragment>
                        <ModalAddContacts
                            contactType={'customer'}
                            ddlbCountries={this.props.codeTableValues.countries}
                        />
                        <ModalAddProductsServices />
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.props.dataView || this.state}
                            validationSchema={this.handleValidationSchema()}
                            onSubmit={(values) => this.handleFormSubmit(values)}
                        >
                            {({errors, status, touched}) => (
                                <Form className="form-horizontal mb-4">
                                    <Row>
                                        <Col md={12}>
                                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Row className="mb-3">
                                                                <Label className={'col-md-2 col-form-label'} htmlFor="customer">{this.props.t('Customer')}</Label>
                                                                <Col md={8}>
                                                                    <div className="input-group">
                                                                        <div className="form-control border-0 p-0">
                                                                            <Select
                                                                                name="customer"
                                                                                options={ddlbParser(this.props.customers, {
                                                                                    label: 'full_name',
                                                                                    value: 'id',
                                                                                }, true)}
                                                                                onChange={(e) => (this.handleDdlbChange(e, 0, 'customer'))}
                                                                                formatOptionLabel={formatOptionLabelCustomers}
                                                                                classNamePrefix="select2-selection "
                                                                                isClearable={false}
                                                                                isDisabled={this.props.formLoading}
                                                                                required
                                                                                value={this.handleDdlbDefaultValue(0, 'customer')}
                                                                            />
                                                                        </div>
                                                                        <Button onClick={this.handleAddContact} color="primary">+</Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Col md={{
                                                                    offset: 2,
                                                                    size: 10
                                                                }}>
                                                                    <RenderCustomerDetail customer={this.state.customer} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Row className="mb-3">
                                                            <Label className={'col-md-4 col-form-label'} htmlFor="invoice_status">{this.props.t('Invoice Status')}</Label>
                                                                <Col md={8}>
                                                                    <div className="input-group">
                                                                        <div className="form-control border-0 p-0">
                                                                            <Select
                                                                                name="products_services_packages"
                                                                                options={this.props.codeTableValues.payment_statuses}
                                                                                onChange={(e) => (this.handleDdlbChange(e, 0, 'invoice_status'))}
                                                                                classNamePrefix="select2-selection "
                                                                                isClearable={false}
                                                                                isDisabled={this.props.formLoading}
                                                                                required
                                                                                value={this.handleDdlbDefaultValue(0, 'invoice_status')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Label className={'col-md-4 col-form-label'} htmlFor="invoice_date">{this.props.t('Invoice Date')}</Label>
                                                                <Col md={8}>
                                                                    <Flatpickr
                                                                        id="invoice_date"
                                                                        name="invoice_date"
                                                                        className="form-control bg-white"
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "F j, Y",
                                                                            dateFormat: "Y-m-d"
                                                                        }}
                                                                        onChange={(selectedDates, dateStr, instance) => {
                                                                            this.handleDdlbChange(dateStr, 0, 'invoice_date')
                                                                        }}
                                                                        value={this.handleTextDefaultValue('invoice_date')}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Label className={'col-md-4 col-form-label'} htmlFor="invoice_date">{this.props.t('Payment Due Date')}</Label>
                                                                <Col md={8}>
                                                                    <Flatpickr
                                                                        id="payment_due_date"
                                                                        name="payment_due_date"
                                                                        className="form-control bg-white"
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "F j, Y",
                                                                            dateFormat: "Y-m-d"
                                                                        }}
                                                                        onChange={(selectedDates, dateStr, instance) => {
                                                                            this.handleDdlbChange(dateStr, 0, 'payment_due_date')
                                                                        }}
                                                                        value={this.handleTextDefaultValue('payment_due_date')}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <Col>
                                                        <Row className="mb-3">
                                                                <Label className={'col-md-1 col-form-label'} htmlFor="products_services_packages">{this.props.t('Package')}</Label>
                                                                <Col>
                                                                    <div className="input-group">
                                                                        <div className="form-control border-0 p-0">
                                                                            <Select
                                                                                name="products_services_packages"
                                                                                options={ddlbParser(this.props.packages, {
                                                                                    label: 'package_name',
                                                                                    value: 'id',
                                                                                }, true)}
                                                                                onChange={(e) => (this.handleDdlbChange(e, 0, 'products_services_packages'))}
                                                                                formatOptionLabel={formatOptionLabelPackages}
                                                                                classNamePrefix="select2-selection "
                                                                                isClearable={false}
                                                                                isDisabled={this.props.formLoading}
                                                                                required
                                                                                value={this.handleDdlbDefaultValue(0, 'products_services_packages')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{
                                                                    offset: 1
                                                                }}>
                                                                    <RenderPackageDetail package={this.state.package} />
                                                                </Col>
                                                            </Row>
                                                    </Col>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <Label className={'col-md-2 col-form-label pt-0'}>{this.props.t('Add Ons')}</Label>
                                                    <table className="table vertical-align-middle table-striped pt-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="col-md-8">{this.props.t('Product / Service')}</th>
                                                                <th className="col-md-1 text-end">{this.props.t('Quantity')}</th>
                                                                <th className="col-md-1 text-end">{this.props.t('Price')}</th>
                                                                <th className="col-md-1 text-end">{this.props.t('Discount')}</th>
                                                                <th className="col-md-1 text-end">{this.props.t('Amount')}</th>
                                                                <th className="col-md-1">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.props.dataView.package_items[0].add_ons.map((item1, idx) => (
                                                                <tr id={"nested" + idx} key={idx}>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <div className="form-control border-0 p-0">
                                                                                <Select
                                                                                    name="product_service"
                                                                                    options={ddlbParser(this.props.productsServices, {
                                                                                        label: 'name',
                                                                                        value: 'id',
                                                                                    }, true)}
                                                                                    onChange={(e) => (this.handleDdlbChange(e, idx, 'product_service'))}
                                                                                    classNamePrefix="select2-selection "
                                                                                    isClearable={false}
                                                                                    formatOptionLabel={formatOptionLabelProductsServices}
                                                                                    isDisabled={this.props.formLoading}
                                                                                    required
                                                                                    value={this.handleDdlbDefaultValue(idx, 'product_service')}
                                                                                />
                                                                            </div>
                                                                            <Button onClick={this.handleAddProductService} color="primary">+</Button>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <NumericFormat
                                                                            allowNegative={false}
                                                                            name="quantity"
                                                                            type="text"
                                                                            className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'quantity')}
                                                                            disabled={this.props.disableFields}
                                                                            value={this.props.dataView.package_items[0].add_ons[idx].quantity}
                                                                            onChange={(e) => (this.handleRecomputeAddOnAmount(e, idx, 'quantity'))}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="quantity"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Label className="form-control-plaintext text-end">
                                                                            <NumericFormat
                                                                                value={decimalPlace(this.props.dataView.package_items[0].add_ons[idx].price)}
                                                                                displayType="text"
                                                                                thousandSeparator={true}
                                                                                decimalSeparator="."
                                                                            />
                                                                        </Label>
                                                                    </td>
                                                                    <td>
                                                                        <NumericFormat
                                                                            allowNegative={false}
                                                                            name="discount_price"
                                                                            type="text"
                                                                            className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'discount_price')}
                                                                            disabled={this.props.disableFields}
                                                                            value={this.props.dataView.package_items[0].add_ons[idx].discount_price}
                                                                            onChange={(e) => (this.handleRecomputeAddOnAmount(e, idx, 'discount_price'))}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="discount_price"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Label className="form-control-plaintext text-end">
                                                                            <NumericFormat value={decimalPlace(this.props.dataView.package_items[0].add_ons[idx].amount)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                                                                        </Label>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <Button
                                                                            onClick={e =>
                                                                                this.handleRemoveRow(e, idx)
                                                                            }
                                                                            color="danger"
                                                                            className="btn-block inner"
                                                                            value={idx}
                                                                        >
                                                                            <i className="bx bx-trash" />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={4}>
                                                                    <Button onClick={this.handleAddRow} color="primary">{this.props.t('Add Item')}</Button>
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <Label className="form-control-plaintext text-end font-size-13">
                                                                        <strong>{this.props.t('Total')} ({this.props.dataView.invoice.currency_symbol}): <NumericFormat value={decimalPlace(this.props.dataView.invoice.add_ons_amount)} displayType="text" thousandSeparator={true} decimalSeparator="." /></strong>
                                                                    </Label>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                    <Row className="mb-3">
                                                        <Col className="text-end col-11">
                                                            <Label className={'col-form-label'} htmlFor="notes">{this.props.t('Package Discount')}:</Label>
                                                        </Col>
                                                        <Col>
                                                            <NumericFormat
                                                                allowNegative={false}
                                                                decimalSeparator="."
                                                                name="package_discount_price"
                                                                type="text"
                                                                className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'package_discount_price')}
                                                                disabled={this.props.disableFields}
                                                                value={this.props.dataView.invoice.discount_price}
                                                                onChange={(e) => (this.handleRecomputeTotalAmount(e, 'package_discount_price'))}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="p-3 mb-3 bg-dark bg-opacity-10 text-end font-size-14">
                                                                <strong>{this.props.t('Amount Due')} ({this.props.dataView.invoice.currency_symbol}): <NumericFormat value={decimalPlace(Number(this.props.dataView.invoice.package_amount) + Number(this.props.dataView.invoice.add_ons_amount) - Number(this.props.dataView.invoice.discount_price))} displayType="text" thousandSeparator={true} decimalSeparator="." /></strong>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <Label className={'col-md-2 col-form-label pt-0'} htmlFor="notes">{this.props.t('Notes')}</Label>
                                                    <Field
                                                        name="notes"
                                                        as="textarea"
                                                        className={'form-control' + this.handleFieldValidation(errors, touched, 'notes')}
                                                        disabled={this.props.disableFields}
                                                        rows={5}
                                                        placeholder="Enter notes or terms of service that are visible to the customer"
                                                        value={this.handleTextDefaultValue('notes')}
                                                        onChange={(e) => (this.handleTextChange(e, 'notes'))}
                                                    />
                                                    <ErrorMessage
                                                        name="notes"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={12}>
                                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="text-end">
                                                    <ButtonLoading
                                                        formLoading={this.props.formLoading}
                                                        buttonClass={'btn btn-primary w-md me-3'}
                                                        onClick={() => {this.props.invoicesSubmitExportAction(false)}}
                                                    />
                                                    <ButtonLoading
                                                        buttonLoadingText={<React.Fragment><i className="bx bxs-file-pdf"></i> {this.props.t('Generating')}</React.Fragment>}
                                                        buttonName={<React.Fragment><i className="bx bxs-file-pdf"></i> {this.props.t('Submit and Generate PDF')}</React.Fragment>}
                                                        formLoading={this.props.formLoading}
                                                        buttonClass={'btn btn-success'}
                                                        onClick={() => {this.props.invoicesSubmitExportAction(true)}}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </React.Fragment>
                ) : (
                    <div className="text-center"><Spinner className="m-2" color="primary"/></div>
                )}
            </React.Fragment>
        )
    }
}

FormUpdateInvoices.propTypes = {
    t: PropTypes.any,
    invoiceId: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const productsServices = {
        ...state.ProductsServices.data
    }

    const customers = {
        ...state.Contacts.data
    }

    const codeTableValues = {
        ...state.CodeTable.data
    }

    const packages = {
        ...state.PackageBuilder.data
    }

    const {
        submitExport,
        response,
        alertType,
        dataView,
    } = state.Invoices

    return {
        productsServices,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        customers,
        codeTableValues,
        submitExport,
        dataView,
        packages,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    productsServicesAction,
    contactsAction,
    invoicesInitAction,
    invoicesUpdateAction,
    codeTableOptionsAction,
    componentModalToggleAction,
    invoicesSubmitExportAction,
    componentFormOnSubmitAction,
    componentFormInitAction,
    invoicesViewAction,
    invoicesFormAction,
    invoicesFormItemsAction,
    invoiceFormDeleteRowAction,
    packageBuilderDetailsAction
})(FormUpdateInvoices))