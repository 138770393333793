import {
    BOOKING_EVENTS_DATA,
    BOOKING_EVENTS,
    BOOKING_EXPORT_LOADED,
    BOOKING_EXPORT_LOADING,
    BOOKING_EXPORT,
    BOOKINGS_ADD,
    BOOKINGS_DATA,
    BOOKINGS_DELETE,
    BOOKINGS_FAIL,
    BOOKINGS_FORM,
    BOOKINGS_INIT,
    BOOKINGS_LOADED_DATA,
    BOOKINGS_LOADING_DATA,
    BOOKINGS_SUCCESS,
    BOOKINGS_UPDATE,
    BOOKINGS_VIEW_DATA,
    BOOKINGS_VIEW,
    BOOKINGS,
} from './actionTypes'

export const bookingsInitAction = (params) => ({
    type: BOOKINGS_INIT,
    payload: params,
})

export const bookingsAction = (params) => ({
    type: BOOKINGS,
    payload: {params},
})

export const bookingsDataAction = (data) => ({
    type: BOOKINGS_DATA,
    payload: {data},
})

export const bookingsAddAction = (params, meta) => ({
    type: BOOKINGS_ADD,
    payload: {params, meta},
})

export const bookingsUpdateAction = (params, meta) => ({
    type: BOOKINGS_UPDATE,
    payload: {params, meta},
})

export const bookingsDeleteAction = (params, meta) => ({
    type: BOOKINGS_DELETE,
    payload: {params, meta},
})

export const bookingsFormAction = (params) => ({
    type: BOOKINGS_FORM,
    payload: {params},
})

export const bookingsSuccessAction = (params) => ({
    type: BOOKINGS_SUCCESS,
    payload: params,
})

export const bookingsFailAction = (params) => ({
    type: BOOKINGS_FAIL,
    payload: params,
})

export const bookingEventsAction = (params) => ({
    type: BOOKING_EVENTS,
    payload: {params},
})

export const bookingEventsDataAction = (data) => ({
    type: BOOKING_EVENTS_DATA,
    payload: {data},
})

export const bookingsViewAction = (params) => ({
    type: BOOKINGS_VIEW,
    payload: {params},
})

export const bookingsViewDataAction = (data) => ({
    type: BOOKINGS_VIEW_DATA,
    payload: {data},
})

export const bookingsExportAction = (params, meta) => ({
    type: BOOKING_EXPORT,
    payload: {params, meta},
})

export const bookingsExportLoadingAction = (params, meta) => ({
    type: BOOKING_EXPORT_LOADING,
    payload: {params, meta},
})

export const bookingsExportLoadedAction = (params, meta) => ({
    type: BOOKING_EXPORT_LOADED,
    payload: {params, meta},
})

export const bookingsLoadingDataAction = (params) => ({
    type: BOOKINGS_LOADING_DATA,
    payload: params,
})

export const bookingsLoadedDataAction = (params) => ({
    type: BOOKINGS_LOADED_DATA,
    payload: params,
})