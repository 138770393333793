import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DataTableLoader from '../Common/DataTable/DataTableLoader'
import {Card, CardBody, Col, List, ListInlineItem, Row} from 'reactstrap'
import DataTable from '../Common/DataTable/DataTable'
import {
    invoicesAction,
    invoicesExportAction,
    componentFormInitAction,
    componentModalToggleAction,
    paymentsSetSelectedInvoiceAction,
}  from 'store/actions'
import {
    dateFormat,
    decimalPlace,
    pluckRouteFromName
} from 'app.cms.react/helpers/utils'
import {Link, withRouter} from 'react-router-dom'
import {NumericFormat} from 'react-number-format';
import ModalAddPayment from './ModalAddPayment'

const formatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div className="float-end">
                <List type="inline" className="p-0 m-0">
                    <ListInlineItem>
                        <Link
                            to={pluckRouteFromName('view-invoice', {id: row.id})}
                            className="fw-medium text-primary"
                        >
                            <i className="bx bx-receipt" />
                        </Link>
                    </ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleExport(row, e)
                    }}><i className="bx bxs-file-pdf" /></ListInlineItem>
                    <ListInlineItem>
                        <Link
                            to={pluckRouteFromName('view-invoice-payments', {id: row.id})}
                            className="fw-medium text-primary"
                        >
                            <i className="bx bxs-bank" />
                        </Link>
                    </ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleAddPayment(row, e)
                    }}><i className="bx bx-money"/></ListInlineItem>
                    <ListInlineItem>
                        <Link
                            to={pluckRouteFromName('update-invoice', {id: row.id})}
                            className="fw-medium text-primary"
                        >
                            <i className="bx bx-pencil text-primary"/>
                        </Link>
                    </ListInlineItem>
                </List>
            </div>
        </React.Fragment>
    )
}

const formatInvoice = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            {row.invoice.invoice_prefix_code}-{row.id}
        </React.Fragment>
    )
}

const formatInvoiceStatus = (cell, row, rowIndex, formatExtraData) => {
    let textColor = ''

    switch (row.invoice.invoice_status.payment_status_code) {
        case 'paid':
            textColor = 'text-success'
            break
        case 'partial':
            textColor = 'text-info'
            break
        case 'void':
            textColor = 'text-danger'
            break
    }

    return (
        <React.Fragment>
            <span className={textColor}>{cell}</span>
        </React.Fragment>
    )
}

const formatInvoiceDate = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            {dateFormat(row.invoice.invoice_date)}
        </React.Fragment>
    )
}

const formatPaymentDueDate = (cell, row, rowIndex, formatExtraData) => {
    const paymentDueDate = dateFormat(row.invoice.payment_due_date)
    const daysOverdue = row.invoice.payment_diff_remaining
    let badgeColor = 'primary'

    if (daysOverdue == 0) {
        badgeColor = 'warning'
    } else if (daysOverdue < 0) {
        badgeColor = 'danger'
    }

    return (
        <React.Fragment>
            {paymentDueDate} <span className={'badge badge-soft-' + badgeColor}>{row.invoice.payment_diff_remaining_human}</span>
        </React.Fragment>
    )
}

const formatAmountDue = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            {row.invoice.currency_symbol} <NumericFormat
            value={decimalPlace(row.invoice.total_amount)}
            displayType='text'
            thousandSeparator={true}
            decimalSeparator="."
        />
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'id',
        text: 'Invoice #',
        sort: true,
        formatter: formatInvoice
    },
    {
        dataField: 'invoice.invoice_date',
        text: 'Invoice Date',
        sort: true,
        formatter: formatInvoiceDate
    },
    {
        dataField: 'invoice.invoice_status.payment_status_name',
        text: 'Invoice Status',
        sort: true,
        formatter: formatInvoiceStatus
    },
    {
        dataField: 'invoice.payment_due_date',
        text: 'Due Date',
        sort: true,
        formatter: formatPaymentDueDate
    },
    {
        dataField: 'invoice.created_at',
        text: 'Created Date',
        sort: true,
        formatter: ((cell, row) => {
            return dateFormat(row.invoice.created_at)
        })
    },
    {
        dataField: 'full_name',
        text: 'Customer',
        sort: true,
    },
    {
        dataField: 'invoice.total_amount',
        text: 'Amount Due',
        sort: true,
        formatter: formatAmountDue
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        headerClasses: 'text-end',
        formatter: formatActions,
        formatExtraData: {
            toggleUpdate: props.toggleUpdate,
            toggleDelete: props.toggleDelete,
            toggleExport: props.toggleExport,
            toggleAddPayment: props.toggleAddPayment,
        }
    },
]

class TableInvoices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                id: null,
            },
            page: 1,
            limit: 10,
            toggleAddLinkProps: {
                linkTo: pluckRouteFromName('create-invoice'),
                linkText: 'Add New',
            },
            toggleEditModalProps: {
                target: 'edit',
                isOpen: true
            },
            toggleDeleteModalProps: {
                target: 'delete',
                isOpen: true
            },
            toggleAddPaymentModalProps: {
                target: 'addPayment',
                isOpen: true
            },
        }

        this.props.invoicesAction()
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.toggleExport = this.toggleExport.bind(this)
        this.toggleAddPayment = this.toggleAddPayment.bind(this)
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
            }
        })
    }

    toggleExport(row) {
        this.props.invoicesExportAction({
            invoice_id: row.id
        })
    }

    toggleView(row) {

    }

    toggleUpdate(row) {

    }

    toggleDelete(row) {

    }

    toggleAddPayment(row) {
        this.props.paymentsSetSelectedInvoiceAction(row.id)
        this.setStateSelected(row)
        this.props.componentFormInitAction()
        this.props.componentModalToggleAction(this.state.toggleAddPaymentModalProps)
    }

    render() {
        return (
            <React.Fragment>
                <ModalAddPayment />
                <DataTableLoader
                    data={this.props.data}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddLink={this.state.toggleAddLinkProps}
                    dataTable={
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                columns={columns({
                                                    toggleUpdate: this.toggleUpdate,
                                                    toggleDelete: this.toggleDelete,
                                                    toggleExport: this.toggleExport,
                                                    toggleAddPayment: this.toggleAddPayment,

                                                })}
                                                data={this.props.data}
                                                isDataTableLoading={this.props.isDataTableLoading}
                                                toggleAddLink={this.state.toggleAddLinkProps}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                />
            </React.Fragment>
        )
    }
}

TableInvoices.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    const {
        data,
        alertType,
        response,
    } = state.Invoices

    return {
        isDataTableLoading,
        data,
        alertType,
        response,
    }
}

export default connect(mapStateToProps, {
    invoicesAction,
    invoicesExportAction,
    componentFormInitAction,
    componentModalToggleAction,
    paymentsSetSelectedInvoiceAction,
})(withRouter(withTranslation()(TableInvoices)))