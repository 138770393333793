import {takeEvery, put, call} from 'redux-saga/effects'
import {
    BOOKINGS,
    BOOKINGS_ADD,
    BOOKINGS_UPDATE,
    BOOKINGS_DELETE,
    BOOKINGS_VIEW,
    BOOKING_EVENTS,
    BOOKING_EXPORT,
} from './actionTypes'
import {
    componentDataTableLoadedAction,
    componentDataTableLoadingAction
} from 'app.cms.react/store/components/dataTable/actions'
import {
    bookingEventsDataAction,
    bookingsDataAction, bookingsExportLoadedAction,
    bookingsExportLoadingAction,
    bookingsFailAction,
    bookingsSuccessAction,
    bookingsViewDataAction,
    bookingsLoadedDataAction,
    bookingsLoadingDataAction,
} from './actions'
import * as API_URL from '../actionTypes'
import {apiDel, apiGet, apiPost, apiPostDownload, apiPut} from 'app.cms.react/helpers/apiHelpers'
import {containsWord, formArgsExcludeEmpty, getErrorResponse, getPropTraverse} from 'app.cms.react/helpers/utils'
import {componentFormOnSubmitDoneAction} from 'app.cms.react/store/components/form/actions'
import {
    AJAX_URL_BOOKING_EVENTS,
    AJAX_URL_BOOKINGS,
    AJAX_URL_BOOKING_EXPORT
} from '../actionTypes'

function* bookings({payload: {params, history}}) {
    try {
        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_BOOKINGS)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(bookingsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* bookingEvents({payload: {params, history}}) {
    try {
        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_BOOKING_EVENTS)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(bookingEventsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

const groupByEntries = (haystack, hay) => {
    let obj = {}
    let collection = {}
    let property = null
    let valueA = {}

    // group event details by venue type
    Object.entries(haystack).map(([keyV, valueV]) => {
        property = null
        collection = {}
        Object.entries(hay).map(([key, value]) => {
            if (containsWord(key, valueV)) {
                property = valueV
                if (typeof value == 'object') {
                    Object.entries(value).map(([k, v]) => {
                        if (v != '') {
                            valueA = {...valueA, ...{[k]: v}}
                        }
                    })
                    if (Object.entries(valueA).length > 0) {
                        collection = {...collection, ...{[key]: valueA}}
                    }
                } else if ((typeof value == 'string' || typeof value == 'number') && value != '') {
                    collection = {...collection, ...{[key]: value}}
                }
            }
        })
        collection = formArgsExcludeEmpty(collection)
            obj[property] = collection
    })

    return obj
}

const excludeUnselectedItems = (haystack) => {
    let arr = []

    Object.entries(haystack).map(([key, value]) => {
        if (value.is_selected == 1) {
            arr.push(value)
        }
    })

    return arr
}

function* bookingsAdd({payload: {params, meta}}) {
    const contactPerson = {
        contact_person_first_name: params.contact_person_first_name,
        contact_person_last_name: params.contact_person_last_name,
        contact_person_email: params.contact_person_email,
        contact_person_contact_number: params.contact_person_contact_number,
    }
    const options = formArgsExcludeEmpty({
        category: getPropTraverse(params, ['category', 'category_code']),
        category_type: getPropTraverse(params, ['category', 'category_type_code']),
        package: params.package,
        products_services_addons: excludeUnselectedItems(getPropTraverse(params, ['products_services_addons'])),
        contact_person: (params.contact_person.id == '') ? formArgsExcludeEmpty(contactPerson) : formArgsExcludeEmpty(params.contact_person),
        event_details: {...{guest_count: params.guest_count}, ...formArgsExcludeEmpty(groupByEntries(params.venue_types, params))},
        persona: groupByEntries(params.persona_types, params),
        form_action: params.form_action,
        token: params.token,
    })

    let componentResponse = {}

    try {
        const response = yield call(apiPost, API_URL.AJAX_URL_BOOKINGS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(bookingsSuccessAction(componentResponse))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(bookingsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* bookingsUpdate({payload: {params, meta}}) {
    const options = formArgsExcludeEmpty({
        booking_id: params.booking_id,
        category: getPropTraverse(params, ['category', 'category_code']),
        category_type: getPropTraverse(params, ['category', 'category_type_code']),
        package: params.package,
        contact_person: params.contact_person,
        event_details: {...{guest_count: params.guest_count}, ...formArgsExcludeEmpty(groupByEntries(params.venue_types, params))},
        persona: groupByEntries(params.persona_types, params)
    })

    let componentResponse = {}

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_BOOKINGS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(bookingsSuccessAction(componentResponse))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(bookingsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* bookingsDelete({payload: {params, meta}}) {
    const options = {
        data: {
            id: params.primaryKey.id,
        }
    }
    let componentResponse = {}

    try {
        const response = yield call(apiDel, API_URL.AJAX_URL_BOOKINGS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(bookingsSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_BOOKING_EVENTS)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(bookingEventsDataAction(data))

        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(bookingsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* bookingsView({payload: {params, history}}) {
    try {
        yield put(bookingsLoadingDataAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_BOOKINGS_VIEW, {params: {booking_id: params.id}})
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(bookingsViewDataAction(data))
        yield put(bookingsLoadedDataAction())
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
        yield put(bookingsLoadedDataAction())
    }
}

function* bookingsExport({payload: {params, meta}}) {
    const options = {
        booking_id: params.booking_id,
    }

    try {
        yield put(bookingsExportLoadingAction())
        yield call(apiPostDownload, API_URL.AJAX_URL_BOOKING_EXPORT, options, 'contract.pdf')
    } catch (error) {

    }
    yield put(bookingsExportLoadedAction())
}

function* BookingsSaga() {
    yield takeEvery(BOOKINGS, bookings)
    yield takeEvery(BOOKING_EVENTS, bookingEvents)
    yield takeEvery(BOOKINGS_ADD, bookingsAdd)
    yield takeEvery(BOOKINGS_UPDATE, bookingsUpdate)
    yield takeEvery(BOOKINGS_DELETE, bookingsDelete)
    yield takeEvery(BOOKINGS_VIEW, bookingsView)
    yield takeEvery(BOOKING_EXPORT, bookingsExport)
}

export default BookingsSaga