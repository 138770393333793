import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Button, Card, CardBody, Col, Row, Spinner} from 'reactstrap'
import PropTypes from 'prop-types'
import 'app.cms.react/assets/scss/invoice.scss'
import logo from 'assets/images/logo/lomograpika-logo.jpg'
import {invoicesViewAction, invoicesExportAction} from 'app.cms.react/store/actions'
import {dateFormat, decimalPlace, getPropTraverse} from 'app.cms.react/helpers/utils'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import {NumericFormat} from 'react-number-format'
import InvoiceItems from 'app.cms.react/components/Invoices/InvoiceItems'
import InvoiceItemsByPackage from 'app.cms.react/components/Invoices/InvoiceItemsByPackage'
import parse from 'html-react-parser'

const RenderButtonLoading = props => {
    return (
        <ButtonLoading
            formLoading={props.isInvoiceExportLoading}
            buttonType="button"
            onClick={() => props.invoicesExportAction({
                invoice_id: props.invoiceId
            })}
            buttonLoadingText={<React.Fragment><i className="bx bxs-file-pdf"></i> {props.t('Generating')}</React.Fragment>}
            buttonName={<React.Fragment><i className="bx bxs-file-pdf"></i> {props.t('Generate PDF')}</React.Fragment>}
        />
    )
}

const RenderFooterList = props => {
    if (props.value != null) {
        return (<React.Fragment><div className="mb-2">{props.label}: {props.value}</div></React.Fragment>)
    }

    return null
}

class InvoiceWebView extends Component {
    constructor(props) {
        super(props)

        this.props.invoicesViewAction({invoice_id: this.props.invoiceId})
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                {(Object.entries(this.props.dataView).length > 0 && this.props.isDataTableLoading == false) ? (
                                    <React.Fragment>
                                        <Row className="mb-3">
                                            <Col md={12} className="text-end">
                                                <RenderButtonLoading {...this.props} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <img src={logo} className="img-fluid mb-5" />
                                            </Col>
                                            <Col md={10} className={'text-end'}>
                                                <div className="invoice-title">
                                                    <h1 className="text-uppercase">{this.props.t('Invoice')}</h1>
                                                    <dl className="detail-list">
                                                        <dt className={'mb-2'}>{this.props.dataView.business_name}</dt>
                                                        <dd>
                                                            {(this.props.dataView.business_address_line_1 != null) ? this.props.dataView.business_address_line_1 + ', ' : null}
                                                            {(this.props.dataView.business_address_line_2 != null) ? this.props.dataView.business_address_line_2 + ', ' : null}
                                                            {(this.props.dataView.business_country_name != null) ? this.props.dataView.business_country_name : null}
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <dl>
                                                    <dt className={'mb-2'}>{this.props.t('Billed to')}</dt>
                                                    <dd>{this.props.dataView.full_name}</dd>
                                                    <dd>{this.props.dataView.contact_number}</dd>
                                                    <dd>{this.props.dataView.email}</dd>
                                                </dl>
                                            </Col>
                                            <Col md={6}>
                                                <div className="float-end">
                                                    <table className="table-borderless table-unpadded-bt">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-end"><strong>Invoice Number:</strong></td>
                                                                <td>{this.props.dataView.invoice.invoice_code}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-end pdf-media-bold"><strong>Invoice Date:</strong></td>
                                                                <td>{dateFormat(this.props.dataView.invoice.invoice_date)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-end pdf-media-bold"><strong>Payment Due Date:</strong></td>
                                                                <td>{dateFormat(this.props.dataView.invoice.payment_due_date)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-end pdf-media-bold"><strong>Amount Due:</strong></td>
                                                                <td>{this.props.dataView.invoice.currency_symbol} <NumericFormat
                                                                    value={decimalPlace(this.props.dataView.invoice.total_amount)}
                                                                    displayType="text"
                                                                    thousandSeparator={true}
                                                                    decimalSeparator="."
                                                                />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {(getPropTraverse(this.props.dataView, ['package_items']) != null) ? (
                                                    <InvoiceItemsByPackage dataView={this.props.dataView} />
                                                ) : (
                                                    <InvoiceItems dataView={this.props.dataView} />
                                                )}
                                            </Col>
                                        </Row>
                                        {(this.props.dataView.invoice.notes != null) ? (
                                            <React.Fragment>
                                                <Row>
                                                    <Col>
                                                        <div className="alert alert-light parse-html-wrap">
                                                            <strong>Notes:</strong>
                                                            <div>{parse(this.props.dataView.invoice.notes)}</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ) : null}
                                        <Row>
                                            <Col>
                                                <div className="mb-2">Payment to: Joy Marie Chew (recommended via online transaction)</div>
                                                <div className="mb-2">BPI Account: 0039414384 (QR code upon request)</div>
                                                <div className="mb-2">BDO Account: 00895005209</div>
                                                <div className="mb-2">GCash or PayMaya: 09255606052</div>
                                                <div className="mb-2">** Please send a clear copy of the deposit slip or screenshot of online transaction to hello@lomograpika.com</div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <div className="mb-2"><strong>{this.props.dataView.business_name}</strong></div>
                                                <RenderFooterList label={'Email'} value={this.props.dataView.vendor_email} />
                                                <RenderFooterList label={'Contact Number'} value={this.props.dataView.business_contact_number} />
                                                <div className="mb-2">Website: https://www.lomograpika.com</div>
                                                <div className="mb-2">Facebook: https://www.facebook.com/lomograpika</div>
                                                <div className="mb-2">Instagram: https://www.instagram.com/lomograpika</div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12} className="text-end">
                                                <RenderButtonLoading {...this.props} />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                ) : (
                                    <div className="text-center"><Spinner className="m-2" color="primary"/></div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

InvoiceWebView.propTypes = {
    t: PropTypes.any,
    invoiceId: PropTypes.any.isRequired
}

const mapStateToProps = state => {
    const {
        dataView,
        isInvoiceExportLoading,
    } = state.Invoices

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        dataView,
        isInvoiceExportLoading,
        isDataTableLoading
    }
}

export default withTranslation()(connect(mapStateToProps, {
    invoicesViewAction,
    invoicesExportAction,
})(InvoiceWebView))