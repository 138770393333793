import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {CardTitle, Col, Input, Label, Row} from 'reactstrap'
import {NumericFormat} from 'react-number-format'
import {decimalPlace, getPropTraverse} from 'app.cms.react/helpers/utils'
import {
    bookingsFormAction,
    productsServicesAction,
    productsServicesDataAction,
    productsServicesFormItemsAction,
    productsServicesFormItemsSelectedAction
} from 'store/actions'

class ProductsServicesAddOns extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products_services_addons: []
        }

        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleCheckItem = this.handleCheckItem.bind(this)
        this.handleTotalPackagePrice = this.handleTotalPackagePrice.bind(this)
    }

    handleOnChange(value, idx) {
        const stateList = Object.entries(this.props.data).map(([key, val]) => {
            let quantityInt = 1
            let quantityMax = 10

            if (key == idx) {
                value = (value <= 0) ? 1 : value
                value = (value > quantityMax) ? quantityMax : value
                return {
                    ...val,
                    is_selected: this.props.dataView[key].is_selected,
                    quantity: value,
                    price: value * val.price,
                }
            } else {
                quantityInt = (typeof this.props.dataView[key].quantity == 'undefined') ? quantityInt : this.props.dataView[key].quantity
                quantityInt = (quantityInt > quantityMax) ? quantityMax : quantityInt

                return {
                    ...val,
                    is_selected: this.props.dataView[key].is_selected,
                    quantity: (quantityInt <= 0) ? 1 : quantityInt,
                    price: this.props.dataView[key].price,
                }
            }
        })

        this.props.productsServicesFormItemsAction(stateList)
        this.props.bookingsFormAction({products_services_addons: stateList})
    }

    handleCheckItem(value, idx, isChecked) {
        const stateList = Object.entries(this.props.dataView).map(([key, val]) => {
            if (key == idx) {
                return {
                    ...val,
                    is_selected: (isChecked == true) ? 1 : 0,
                    quantity: (typeof this.props.dataView[key].quantity != 'undefined') ? this.props.dataView[key].quantity : 1
                }
            } else {
                return {
                    ...val,
                    is_selected: (typeof this.props.dataView[key].is_selected != 'undefined') ? this.props.dataView[key].is_selected : 0,
                    quantity: (typeof this.props.dataView[key].quantity != 'undefined') ? this.props.dataView[key].quantity : 1
                }
            }
        })

        this.props.productsServicesFormItemsAction(stateList)
        this.props.bookingsFormAction({products_services_addons: stateList})
    }

    handleTotalPackagePrice() {
        let packagePrice = getPropTraverse(this.props.bookingForm, ['package', 'package_price'])
        packagePrice = (packagePrice == null) ? 0.00 : packagePrice
        const addOns = this.props.bookingForm.products_services_addons
        let price = 0.00
        let total = 0.00

        Object.entries(addOns).map(([key, val]) => {
            if (val.is_selected == 1) {
                price = parseFloat(price) + parseFloat(val.price)
            }
        })

        total = parseFloat(packagePrice) + price

        return total.toFixed(2)
    }

    render() {
        let isFound = false

        return (
            <React.Fragment>
                {(Object.entries(this.props.dataView).length > 0) ? (
                    <React.Fragment>
                        <CardTitle>{this.props.t('Add-Ons')}</CardTitle>
                        <Row>
                            <Col>
                                <table className="table table-hover mb-0">
                                    <tbody>
                                    {this.props.dataView.map((item, idx) => (
                                        <tr key={idx}>
                                            <td className="w-40px">
                                                <div className="form-check">
                                                    {
                                                          isFound = this.props.bookingForm.products_services_addons.some(
                                                            addon => addon.product_service_id === item.id
                                                          )
                                                    }
                                                    <Input
                                                        name="id"
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={'products-services' + idx}
                                                        value={item.id}
                                                        // checked={isFound}
                                                        onChange={(e) => {
                                                            this.handleCheckItem(this.props.dataView[idx], idx, e.target.checked)
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-2">
                                                    <Label className="form-check-label fw-medium" htmlFor={'products-services-' + idx}>{item.name}</Label>
                                                    {(item.description != null) ? <React.Fragment><div className="text-muted">{item.description}</div></React.Fragment> : null}
                                                </div>
                                                <div>
                                                    <div className="d-inline">
                                                        <input
                                                            name="quantity"
                                                            className="form-control d-inline w-80px"
                                                            type="number"
                                                            min="1"
                                                            max="10"
                                                            value={(typeof item.quantity == 'undefined') ? 1 : item.quantity}
                                                            onChange={(e) => {this.handleOnChange(e.target.value, idx, item.price)}}
                                                        />
                                                    </div>
                                                    <div className="d-inline mx-2">
                                                        =
                                                    </div>
                                                    <div className="d-inline">
                                                        <NumericFormat value={decimalPlace(item.price)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-info bg-opacity-10">
                                            <td colSpan="2" className="font-size-14">
                                                <strong>Total Package Price:</strong> <NumericFormat value={this.handleTotalPackagePrice()} displayType="text" thousandSeparator={true} decimalSeparator="." />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        )
    }
}

ProductsServicesAddOns.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        data,
        dataView
    } = state.ProductsServices

    const bookingForm = {
        ...state.Bookings.form
    }

    return {
        bookingForm,
        data,
        dataView,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    bookingsFormAction,
    productsServicesAction,
    productsServicesDataAction,
    productsServicesFormItemsAction,
    productsServicesFormItemsSelectedAction,
})(ProductsServicesAddOns))